import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { HttpService } from './http.service';


// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
// import { Profile } from '../../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService ) { }
// -----------------------------------------------------------------------------
//  Create template
// -----------------------------------------------------------------------------
create( data: any ): Observable<any> {

  return this.httpService.post( `/notification/template`,  data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getList(  options: any = [] ): Observable<any> {

    return this.httpService.getList( `/notification/templates`, options).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Update template
// -----------------------------------------------------------------------------
update( id: string, data: any ): Observable<any> {

  return this.httpService.patch( `/notification/template/${ id }`, data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Delete template
// -----------------------------------------------------------------------------
delete( id: string ): Observable<any> {

  return this.httpService.delete( `/notification/template/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}


// -----------------------------------------------------------------------------
//  Update configuration
// -----------------------------------------------------------------------------
updateConfiguration( data: any ): Observable<any> {

  return this.httpService.patch( `/settings/mail`, data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get configuration
// -----------------------------------------------------------------------------
getConfiguration(  options: any = [] ): Observable<any> {

  return this.httpService.getList( `/settings/mail`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}

// // -----------------------------------------------------------------------------
// //  Get user list
// // -----------------------------------------------------------------------------
//   getById( id: string): Observable<UsersResponse> {

//     return this.httpService.get( `/user/${ id }`).pipe( map( (response: UsersResponse) => {

//       return response;

//     }, catchError( err => {

//       return throwError(err);

//     } )));

//   }
// // -----------------------------------------------------------------------------
// //  Create user
// // -----------------------------------------------------------------------------
// createUser( user: string ): Observable<any> {

//   return this.httpService.post( `/user`, { user } ).pipe( map( (response: any) => {

//     return response;

//   }, catchError( err => {

//     return throwError(err);

//   } )));

// }
// // -----------------------------------------------------------------------------
// //  Confirm user
// // -----------------------------------------------------------------------------
// confirmAccount( token: string, password: string ): Observable<any> {

//   return this.httpService.post( `/user/confirm`, { token, password } ).pipe( map( (response: any) => {

//     return response;

//   }, catchError( err => {

//     return throwError(err);

//   } )));

// }
// // -----------------------------------------------------------------------------
// //  Update profile
// // -----------------------------------------------------------------------------
// updateProfile( id: string, data: any ): Observable<any> {

//   return this.httpService.patch( `/user/profile/${ id }`, data ).pipe( map( (response: any) => {

//     return response;

//   }, catchError( err => {

//     return throwError(err);

//   } )));

// }
// // -----------------------------------------------------------------------------
// //  Delete user
// // -----------------------------------------------------------------------------
// deleteUser( id: string ): Observable<any> {

//   return this.httpService.delete( `/user/${ id }` ).pipe( map( (response: any) => {

//     return response;

//   }, catchError( err => {

//     return throwError(err);

//   } )));

// }




}
