import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/services.index';
import { Subscription } from 'rxjs';
import { Alert } from '../../i../../interfaces/platform/alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {

  eventsubscription: Subscription;
  notifications = [];
  timer = null;
// -----------------------------------------------------------------------------
//  Constructor
// -----------------------------------------------------------------------------
  constructor( private router: Router,
               private alertService: AlertService ) {

    this.eventsubscription = this.alertService.getNotification().subscribe(( text: Alert ) => {
      this.create(text);
    });
  }
// -----------------------------------------------------------------------------
//  Remove notifications
// -----------------------------------------------------------------------------
  removeNotifications(): void {

    const now = new Date().getTime();
    if (  this.notifications[0].timeout < now ) {
      this.notifications.shift();

      if (this.notifications.length === 0) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  }
// -----------------------------------------------------------------------------
//  Create notification
// -----------------------------------------------------------------------------
  create( data: Alert ): void {
    const time = new Date().getTime() + 10000;

    this.notifications.push({
      data,
      timeout: time
    });

    if ( this.timer === null ) {
      this.timer = setInterval(() => {
        this.removeNotifications();
      }, 1000 );
    }
  }
}
