import { Injectable } from '@angular/core';
import { map, catchError, retry, timeout } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { HttpService } from './http.service';

import { UsersResponse } from '../../interfaces/platform/user-service/response-users.interface';

// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
// import { Profile } from '../../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {


// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService ) { }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getList(  options: any = [] ): Observable<UsersResponse> {

    return this.httpService.getList( `/users`, options).pipe( map( (response: UsersResponse) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getById( id: string): Observable<UsersResponse> {

    return this.httpService.get( `/user/${ id }`).pipe( map( (response: UsersResponse) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Create user
// -----------------------------------------------------------------------------
createUser( dataUser: any, role: string ): Observable<any> {

  return this.httpService.post( `/user/${ role }`, dataUser ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Confirm user
// -----------------------------------------------------------------------------
confirmAccount( token: string, password: string ): Observable<any> {

  return this.httpService.post( `/user/confirm`, { token, password } ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Update profile
// -----------------------------------------------------------------------------
updateProfile( id: string, data: any ): Observable<any> {

  return this.httpService.patch( `/user/profile/${ id }`, data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Delete user
// -----------------------------------------------------------------------------
deleteUser( id: string ): Observable<any> {

  return this.httpService.delete( `/user/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Recover password
// -----------------------------------------------------------------------------
recoverPassword( user: string ): Observable<any> {
  return this.httpService.post( `/user/recoverpassword`, { user } )
  .pipe( map( (response: any) => {

    return response.businessResponse;

  }, catchError( err => {

    return throwError(err);

  } )));

}




}
