<ng-container *ngIf="notifications.length > 0">
    <div class="card-container" fxLayout="column" fxLayoutAlign="space-around center">

        <mat-card *ngFor="let item of notifications" class="card animate__animated animate__bounceInRight" [ngClass]="{ 'card-error': item.data.type === 'error', 'card-ok': item.data.type === 'ok' }">
            <div class="msg-container" fxLayout="row" fxLayoutAlign="start center">
                <div class="icon-container" fxFlex="10">
                    <mat-icon class="icon-ok" *ngIf="item.data.type === 'ok'">check_circle_outline</mat-icon>
                    <mat-icon class="icon-info" *ngIf="item.data.type === 'info'">error_outline</mat-icon>
                    <mat-icon class="icon-error" *ngIf="item.data.type === 'error'">highlight_off</mat-icon>
                </div>
                <div class="text-container" fxFlex="90">
                    <p class="title">{{ item.data.title }}</p>
                    <p class="text">{{ item.data.text }}</p>
                </div>
            </div>
        </mat-card>

    </div>
</ng-container>