import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {


  private componentRef = new BehaviorSubject(null);
  component = this.componentRef.asObservable();

  componentResponse = new Subject<any>();
  componentRequest = new Subject<any>();

  constructor(private cfr: ComponentFactoryResolver) {
  }

  view( componentData: any ): void {
    this.componentRef.next( componentData );

    const int = setInterval(() => {
      this.componentRequest.next( componentData );
      clearInterval(int);
    }, 500);

  }

  closeView(): void {
    this.componentRef.next(null);
  }

  sendData( data: any ): void {
    this.componentResponse.next( data );
    this.componentRef.next(null);
  }

  async loadComponent(vcr: ViewContainerRef, componentData: any): Promise<any> {

    if ( componentData === null ) {
      vcr.clear();
      return null;
    }

    let component: any;
    switch (componentData.controller) {
      case 'dialog-window':
        const { DialogWindowComponent } = await import('../../components/dialog-window/dialog-window.component');
        component = DialogWindowComponent;
        break;

      case 'search-policy':
        const { SearchPolicyComponent } = await import('../../components/search-policy/search-policy.component');
        component = SearchPolicyComponent;
        break;

      case 'search-customer':
          const { SearchCustomerComponent } = await import('../../components/search-customer/search-customer.component');
          component = SearchCustomerComponent;
          break;

          case 'search-customer-house':
            const { SearchCustomerHouseComponent } = await import('../../components/search-customer-house/search-customer-house.component');
            component = SearchCustomerHouseComponent;
            break;

      case 'load-image':
          const { LoadImageComponent } = await import('../../components/load-image/load-image.component');
          component = LoadImageComponent;
          break;
    }


    vcr.clear();
    return vcr.createComponent( this.cfr.resolveComponentFactory(component) );

  }

}
