import { Injectable } from '@angular/core';
import { map, catchError, retry, timeout } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService ) { }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getCatalog( identifier: string ): Observable<any> {

    return this.httpService.get( `/settings/catalog/${ identifier }` ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Update catalog
// -----------------------------------------------------------------------------
  update( id: string, data: any ): Observable<any> {

    return this.httpService.patch( `/settings/catalog/${ id }`, data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Create catalog
// -----------------------------------------------------------------------------
create( data: any ): Observable<any> {

  return this.httpService.post( `/settings/catalog`, data  ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Delete catalog
// -----------------------------------------------------------------------------
delete( id: string ): Observable<any> {

  return this.httpService.delete( `/settings/catalog/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}







/*



// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getById( id: string): Observable<any> {

    return this.httpService.get( `/user/${ id }`).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }

// -----------------------------------------------------------------------------
//  Confirm user
// -----------------------------------------------------------------------------
confirmAccount( token: string, password: string ): Observable<any> {

  return this.httpService.post( `/user/confirm`, { token, password } ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Update profile
// -----------------------------------------------------------------------------
updateProfile( id: string, data: any ): Observable<any> {

  return this.httpService.patch( `/user/profile/${ id }`, data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}


*/


}
