import { Component, ViewChild, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgZone} from '@angular/core';
import { filter } from 'rxjs/operators';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService, AlertService } from '../../services/services.index';
import { Observable } from 'rxjs';
import { LoaderService } from '../../services/services.index';

@Component({
  selector: 'app-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss']
})

export class SearchPolicyComponent  implements OnInit {

  @ViewChild(CdkVirtualScrollViewport, { static: true }) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('search', { static: true }) searchElement: ElementRef;

  multiple = true;
  title = 'POLITICAS';
  wordToFind = '';
  fullWindow = false;
  fetchRunning = false;
  searchPageNumber: number;
  searchResults: Array<any>;
  pagesize = 50;
  resetSearchBtn = false;
  dataItemsSelected = [];

  authorization = '';
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
constructor( private loaderService: LoaderService,
             private securityService: SecurityService,
             private scrollDispatcher: ScrollDispatcher,
             private alertService: AlertService,
             private zone: NgZone ) {

  this.searchPageNumber = 0;
  this.searchResults = [];
  this.dataItemsSelected = [];
}
// -----------------------------------------------------------------------------
//  Init object
// -----------------------------------------------------------------------------
ngOnInit(): void {
  this.nextSearchPage(this.searchPageNumber);

  this.searchElement.nativeElement.focus();
  this.scrollDispatcher.scrolled().pipe(
    filter(event => this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength() && !this.fetchRunning)
  ).subscribe(event => {
    this.searchPageNumber++;
    this.nextSearchPage(this.searchPageNumber);
  });
  this.virtualScroll.scrollToIndex(0, 'smooth');
}
// -----------------------------------------------------------------------------
//  Request next page
// -----------------------------------------------------------------------------
nextSearchPage(pageNumber: number): void {
  this.fetchRunning = true;
  this.getData(pageNumber).subscribe((response) => {
    this.zone.run( () => {
      this.searchResults = this.searchResults.concat(response.businessResponse.items);
      this.fetchRunning = false;
      if ( this.searchResults.length === 0 ) {
        this.alertService.create({ type: 'info', title: 'No hay politicas, verifica tu busqueda' });
      }
    });
  }, err => { this.processError(err); });
}
// -----------------------------------------------------------------------------
//  Get data
// -----------------------------------------------------------------------------
  getData(pageNumber): Observable<any> {
    const options = {
      sort: 'module',
      order: 'asc',
      page: pageNumber,
      pageSize: this.pagesize,
      activeState: 'active',
      search: this.wordToFind
    };
    return this.securityService.getPoliciesList( options );
  }
// -----------------------------------------------------------------------------
//  Run searching
// -----------------------------------------------------------------------------
searchText(): void {
      this.resetSearchBtn = true;
      this.virtualScroll.scrollToIndex(0, 'smooth');
      this.searchPageNumber = 0;
      this.searchResults = [];
      this.dataItemsSelected = [];
      this.nextSearchPage(this.searchPageNumber);
  }
// -----------------------------------------------------------------------------
//  Cancel searching
// -----------------------------------------------------------------------------
cancelSearchInput(): void {
    this.searchText();
    this.resetSearchBtn = false;
  }
// -----------------------------------------------------------------------------
//  Run searching
// -----------------------------------------------------------------------------
onKeydown(event: any): void {
  if (event.key === 'Enter') {
    this.searchText();
  }
}
// -----------------------------------------------------------------------------
//  Select item action
// -----------------------------------------------------------------------------
selectItem(): void {
}
// -----------------------------------------------------------------------------
//  Accept botton
// -----------------------------------------------------------------------------
acceptBotton(): void {
  this.loaderService.sendData( this.dataItemsSelected );
  // console.log(this.dataItemsSelected);
  // this.searchResult.emit( this.dataItemsSelected );
}
// -----------------------------------------------------------------------------
//  Cancel botton
// -----------------------------------------------------------------------------
cancelBotton(): void {
  // this.cancelSearch.emit();

  this.loaderService.closeView();
}
// -----------------------------------------------------------------------------
//  Processes Error from backend
// -----------------------------------------------------------------------------
processError( err ): void {
  this.fetchRunning = false;
  switch ( err ) {
    case 401:
      this.alertService.create({ type: 'info', title: 'La sesión ha sido terminada' });
      this.securityService.singOut();
      break;
    default:
      this.alertService.create({ type: 'error', title: 'Servicio no diponible, intenta más tarde' });
      break;
  }
}
}
