export const environment = {
  // production: false,
  // name: 'localhost',
  // endpoint: {
  //   apiService:  'http://localhost:4200',
  //   portal:      'http://localhost:5000'
  // }

  production: true,
  name: 'Production',
  endpoint: {
    apiService:  'https://api.adinsogas.com.mx',
    portal:      'https://admin.adinsogas.com.mx'
  }

};
