import { Component, OnInit } from '@angular/core';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { LoaderService } from '../../services/services.index';

@Component({
  selector: 'app-dialog-window',
  templateUrl: './dialog-window.component.html',
  styleUrls: ['./dialog-window.component.scss']
})

export class DialogWindowComponent  implements OnInit {

  title = '';
  text = '';
  buttons = [];

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
constructor( private loaderService: LoaderService ) {
  this.loaderService.componentRequest.subscribe( data => {
    this.title = data.title;
    this.text = data.text;
    this.buttons = data.buttons ? data.buttons : [{label: 'OK'}];
  });
}
// -----------------------------------------------------------------------------
//  Init object
// -----------------------------------------------------------------------------
ngOnInit(): void {
}
// -----------------------------------------------------------------------------
//  Run searching
// -----------------------------------------------------------------------------
onKeydown(event: any): void {

  console.log(event.key);
  if (event.key === 'Enter') {
    this.loaderService.sendData( { action: 0 } );
  }
}
// -----------------------------------------------------------------------------
//  Accept botton
// -----------------------------------------------------------------------------
clickBotton( idx: number ): void {
  this.loaderService.sendData( { action: idx } );
}

}
