import { Component, OnInit } from '@angular/core';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService, AlertService } from '../../services/services.index';
import { LoaderService } from '../../services/services.index';

@Component({
  selector: 'app-load-image',
  templateUrl: './load-image.component.html',
  styleUrls: ['./load-image.component.scss']
})

export class LoadImageComponent  implements OnInit {


  title = 'IMAGNES';
  locationResource = '';
  urlResource = '';

  fullWindow = false;
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private loaderService: LoaderService,
               private securityService: SecurityService,
               private alertService: AlertService ) {

    this.loaderService.componentRequest.subscribe( data => {
      this.locationResource = data.locationResource;
      this.urlResource = data.urlResource;
    });
  }
// -----------------------------------------------------------------------------
//  Init object
// -----------------------------------------------------------------------------
ngOnInit(): void {

}
// -----------------------------------------------------------------------------
//  Accept botton
// -----------------------------------------------------------------------------
acceptBotton(): void {
}
// -----------------------------------------------------------------------------
//  Cancel botton
// -----------------------------------------------------------------------------
cancelBotton(): void {
  this.loaderService.sendData( null );
  this.loaderService.closeView();
}
// -----------------------------------------------------------------------------
//  Image loaded
// -----------------------------------------------------------------------------
  fileLoaded(e): void {
    if (e.urlResource === '' ) {
      this.alertService.create({ type: 'error', title: 'Hubo un error al cargar la imagen, Por favor intenta de nuevo' });
    }

    this.loaderService.sendData( e );
  }
// -----------------------------------------------------------------------------
//  Processes Error from backend
// -----------------------------------------------------------------------------
processError( err ): void {
  switch ( err ) {
    case 401:
      this.alertService.create({ type: 'info', title: 'La sesión ha sido terminada' });
      this.securityService.singOut();
      break;
    default:
      this.alertService.create({ type: 'error', title: 'Servicio no diponible, intenta más tarde' });
      break;
  }
}
}
