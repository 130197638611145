<div class="page-container-home">
    <div class="dashboard-row first-margin-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutWrap fxLayoutGap="0.0%" fxLayoutAlign="center">
        <div fxFlex="100%" class="form-col">

            <mat-card class="dashboard-card card-walcome">


                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutWrap fxLayoutGap="0.0%" fxLayoutAlign="center">

                    <div fxFlex="70%" class="form-col">
                        <p class="card-subtitle">{{ greatings }}</p>

                    </div>
                    <div fxFlex="30%" class="form-col" style="padding: 0px 0px; text-align: right;">

                        <p class="card-clock">{{ time | date: 'HH:mm' }}</p>
                        <p class="card-date">{{ time | date: 'yyyy-MM-dd' }}</p>

                    </div>

                </div>


            </mat-card>
        </div>

    </div>


    <br>
    <br>
    <br>
    <br>
</div>