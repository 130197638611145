import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Alert } from '../../interfaces/platform/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<any>();

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( ) {
  }
// -----------------------------------------------------------------------------
//  Create new notification
// -----------------------------------------------------------------------------
  create( data: Alert ): void {
    this.subject.next( data );
  }
// -----------------------------------------------------------------------------
//  Get new notification
// -----------------------------------------------------------------------------
  getNotification(): Observable<any>{
    return this.subject.asObservable();
  }
}
