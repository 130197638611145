import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';

import { LocalStorageService } from './localStorage.service';
import { HttpService } from './http.service';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
// import { Profile } from '../../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private subject = new Subject<any>();


  authenticated = false;
  public dataSession: any = {
    authorization: '',
    idUser: '',
    user: '',
    profile: {},
    policies: [],
    menu: []
  };
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService,
               private router: Router,
               private localStorageService: LocalStorageService
              ) {
   this.initSession();
  }
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  initSession(): void {
    const dataSessionString = this.localStorageService.get( 'dataSession' );
    if ( dataSessionString !== null ) {
      this.authenticated = true;
      const dataSessionParse = dataSessionString;
      this.dataSession.authorization = dataSessionParse.authorization;
      this.dataSession.expiresIn = JSON.parse( atob( this.dataSession.authorization.split('.')[1] ) ).exp;
      this.dataSession.idUser = dataSessionParse.idUser;
      this.dataSession.user = dataSessionParse.user;
      this.dataSession.profile = dataSessionParse.profile;
      this.dataSession.policies = dataSessionParse.policies;
      this.dataSession.branchOffice = dataSessionParse.branchOffice;

      // this.dataSession.menu = this.createMenu();

      this.updateCurrentProfile();

    }
  }
// -----------------------------------------------------------------------------
//  Get server status session
// -----------------------------------------------------------------------------
  sessionStatus(): Observable<any> {

    return this.httpService.get( `/security/session/status/${ this.dataSession.authorization }`)
    .pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Start session
// -----------------------------------------------------------------------------
  signIn( user: string, password: string ): Observable<any> {

    return this.httpService.post( '/security/session/init', { user, password } )
    .pipe( map( (response: any) => {

      this.authenticated = true;
      this.dataSession.authorization = response.businessResponse.authorization;
      this.dataSession.expiresIn = JSON.parse( atob( this.dataSession.authorization.split('.')[1] ) ).exp;
      this.dataSession.idUser = response.businessResponse._id;
      this.dataSession.user = response.businessResponse.user;
      this.dataSession.role = response.businessResponse.role;
      this.dataSession.branchOffice = response.businessResponse.branchOffice;
      this.dataSession.policies = response.businessResponse.policies;
      this.dataSession.profile = response.businessResponse.profile;
      this.localStorageService.save( 'dataSession', this.dataSession );
      this.updateCurrentProfile();

      return this.authenticated;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Close session
// -----------------------------------------------------------------------------
singOut(): Observable<any> {

  const ds = this.dataSession.authorization;

  this.dataSession.authorization = '';
  this.dataSession.user = '';
  this.dataSession.policies = [];
  this.dataSession.menu.platform = [];
  this.dataSession.menu.administration = [];
  this.dataSession.menu.modules = [];
  this.authenticated = false;
  this.localStorageService.remove( 'dataSession' );
  this.router.navigate(['/login']);

  return this.httpService.delete( `/security/session/close/${ ds }`)
  .pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Close session
// -----------------------------------------------------------------------------
closeInactiveSesions(): Observable<any> {

  return this.httpService.delete( `/security/session/closeall/inactive`)
  .pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Create new notification
// -----------------------------------------------------------------------------
updateCurrentProfile( profile: any = null): void {

  if ( profile !== null ) {
    this.dataSession.profile = profile;
    this.localStorageService.save( 'dataSession', this.dataSession );
  }
  this.subject.next( {
    profile: this.dataSession.profile,
    user: this.dataSession.user
  });

}
// -----------------------------------------------------------------------------
//  Get user profile
// -----------------------------------------------------------------------------
getCurrentProfile(): Observable<any>{
  return this.subject.asObservable();
}













// -----------------------------------------------------------------------------
//  Validate if token is active
// -----------------------------------------------------------------------------
  tokenValid(): boolean {
    const thisMoment = new Date().getTime() / 1000;
    if (this.dataSession.expiresIn > thisMoment) {
      return true;
    } else {
      return false;
    }
  }
// -----------------------------------------------------------------------------
//  Return status of session
// -----------------------------------------------------------------------------
  isAuthenticated(): boolean {
    return this.authenticated && this.tokenValid();
  }

  amI( id: string ): boolean {
    return this.dataSession.idUser === id;
  }

  getBranchOffice(): string {
    return this.dataSession.branchOffice;
  }
// -----------------------------------------------------------------------------
//  Return user name
// -----------------------------------------------------------------------------
  // getUserName(type: string) {
  //   console.log(this.dataSession.profile);
  //   switch (type) {
  //     case 'full':
  //       // tslint:disable-next-line:max-line-length
  //       return `${ this.dataSession.profile.name.firstName } ${ this.dataSession.profile.name.lastNamePaternal }
  // ${this.dataSession.profile.name.lastNameMaternal }`;
  //       break;
  //     case 'name':

  //       return this.dataSession.profile.name.firstName;
  //       break;
  //     default:
  //       break;
  //   }
  // }
// -----------------------------------------------------------------------------
//  Return user id
// -----------------------------------------------------------------------------
  getUserId(): string {
    return this.dataSession.idUser;
  }
// -----------------------------------------------------------------------------
//  Return user id
// -----------------------------------------------------------------------------
// getPersonId() {
//   return this.dataSession.profile._id;
// }


// -----------------------------------------------------------------------------
//  Get policies list
// -----------------------------------------------------------------------------
getSessionList( options: any = [] ): Observable<any> {

  return this.httpService.getList( `/security/sessions`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}

closeSession( token: string ): Observable<any> {

  return this.httpService.delete( `/security/session/close/${ token }`).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}

deleteSession( id: string ): Observable<any> {

  return this.httpService.delete( `/security/session/delete/${ id }`).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}


// -----------------------------------------------------------------------------
//  Get policies list
// -----------------------------------------------------------------------------
getBinnacleList( options: any = [] ): Observable<any> {

  return this.httpService.getList( `/security/binnacle`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}



// -----------------------------------------------------------------------------
//  Get policies list
// -----------------------------------------------------------------------------
getPoliciesList( options: any = [] ): Observable<any> {

  return this.httpService.getList( `/security/policies`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Create policy
// -----------------------------------------------------------------------------
createPolicy( data: any): Observable<any> {

  return this.httpService.post( `/security/policy`, data).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Edit policy
// -----------------------------------------------------------------------------
editPolicy( id: string, data: any): Observable<any> {

  return this.httpService.patch( `/security/policy/${ id }`, data).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Delete policy
// -----------------------------------------------------------------------------
deletePolicy( id: string ): Observable<any> {

  return this.httpService.delete( `/security/policy/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}


// -----------------------------------------------------------------------------
//  Create policy
// -----------------------------------------------------------------------------
createGroup( data: any): Observable<any> {

  return this.httpService.post( `/security/group`, data).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get policies list
// -----------------------------------------------------------------------------
getGroupsList( options: any = [] ): Observable<any> {

  console.log('jp' , options);
  return this.httpService.getList( `/security/groups`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get policies list
// -----------------------------------------------------------------------------
getGroup( id: string ): Observable<any> {

  return this.httpService.get( `/security/group/${ id }`).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Delete group
// -----------------------------------------------------------------------------
deleteGroup( id: string ): Observable<any> {

  return this.httpService.delete( `/security/group/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Edit group
// -----------------------------------------------------------------------------
editGroup( id: string, data: any): Observable<any> {

  return this.httpService.patch( `/security/group/${ id }`, data).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}


  action( policy: string ): boolean {
    return this.dataSession.policies.find( p => p === policy) !== undefined;
  }

  getAuthorizationsActions(policy: string): any {
    return this.dataSession.policies.filter( p => p.includes(policy) );
  }




  addToMenu( menu: any, module: any, section: any = null ): void {

    const mod = menu.find( item => item.name === module.name);

    console.log(module.name, mod);
    if ( mod === undefined) {

      if ( section !== null ) {
        module.options = [];
        module.options.push( section );
      }
      menu.push ( module );

    } else {

      if ( !mod.options ) {
        mod.options = [];
      }

      mod.options.push( section );

    }

  }




  getMenu(): any {

    const menu: any[] = [];

// -----------------------------------------------------------------------------
//  BUSINESS
// -----------------------------------------------------------------------------




    if (this.action('dashboard.sales.view')) {
      this.addToMenu(menu, {name: 'Dashboard', icon: 'bar_chart'},
      {name: 'Ventas', icon: 'monetization_on', path: '/private/business/dashboard/general'});
    }
    if (this.action('dashboard.customer.view')) {
      this.addToMenu(menu, {name: 'Dashboard', icon: 'bar_chart'},
      {name: 'Ventas para hoy', icon: 'alarm', path: '/private/business/dashboard/salesfortoday'});
    }


    if (this.action('business.branchoffice.view')) {
      this.addToMenu(menu, {name: 'Negocio', icon: 'account_balance'},
        {name: 'Estaciones', icon: 'local_gas_station', path: '/private/business/branchoffice/list'});
    }

    if (this.action('business.customers.view')) {
      this.addToMenu(menu, {name: 'Clientes', icon: 'storefront'},
      {name: 'Condominios', icon: 'apartment', path: '/private/business/customers/condominium/list'});
    }

    if (this.action('business.customers.view')) {
      this.addToMenu(menu, {name: 'Clientes', icon: 'storefront'},
      {name: 'Casa', icon: 'home', path: '/private/business/customers/houses/list'});
    }

    // if (this.action('business.customers.view')) {
    this.addToMenu(menu, {name: 'Ventas', icon: 'monetization_on'},
    {name: 'Factura condominios', icon: 'apartment', path: '/private/business/sales/billing/condominium/list'});
    // }

    this.addToMenu(menu, {name: 'Ventas', icon: 'monetization_on'},
    {name: 'Factura casas', icon: 'home', path: '/private/business/sales/billing/house/list'});


// -----------------------------------------------------------------------------
//  SETTINGS branchoffice
// -----------------------------------------------------------------------------

    if (this.action('security.user.view')) {
      this.addToMenu(menu, {name: 'Ajustes', icon: 'settings'},
      {name: 'Usuarios', icon: 'person', path: '/private/settings/users/list'});
    }

    if (this.action('security.group.view')) {
      this.addToMenu(menu, {name: 'Ajustes', icon: 'settings'},
      {name: 'Grupos', icon: 'people_alt', path: '/private/settings/groups/list'});
    }

    if (this.action('settings.parameters.view')) {
      this.addToMenu(menu, {name: 'Ajustes', icon: 'settings'},
      {name: 'Parametros', icon: 'rule', path: '/private/settings/catalogs/parameters'});
    }



// -----------------------------------------------------------------------------
//  ADMINISTRATION
// -----------------------------------------------------------------------------
    // if (this.action('admin.catalogs.view')) {
    //   this.addToMenu(menu, {name: 'Administración', icon: 'security'}, {name: 'Catálogos', icon: 'list', options: [
    //       {name: 'Inmuebles', icon: 'home', path: '/private/admin/catalogs/list/immovable-service'},
    //       {name: 'Usuarios', icon: 'person', path: '/private/admin/catalogs/users'}
    //   ]});
    // }

    if (this.action('admin.catalogs.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Catálogos', icon: 'ballot',  path: '/private/admin/catalogs/users' });
    }
    if (this.action('admin.catalogs.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Listas', icon: 'list',  path: '/private/admin/catalogs/list/immovable-service' });
    }


    if (this.action('security.policy.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Políticas', icon: 'privacy_tip', path: '/private/admin/policies/list'});
    }

    // if (this.action('admin.mail.view')) {
    //   this.addToMenu(menu, {name: 'Administración', icon: 'security'},
    //   {name: 'Email', icon: 'mail', options: [{name: 'Templates', icon: 'text_snippet', path: '/private/admin/mail/templates'},
    //   {name: 'Buzón', icon: 'markunread_mailbox', path: '/private/admin/mail/mailbox'}]});
    // }

    if (this.action('admin.mail.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Email', icon: 'mail', path: '/private/admin/mail/templates'});
    }

    if (this.action('admin.mail.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Buzón', icon: 'markunread_mailbox', path: '/private/admin/mail/mailbox'});
    }


    if (this.action('security.binnacle.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Bitacora', icon: 'menu_open', path: '/private/admin/binnacle/list'});
    }

    if (this.action('security.session.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Sesiones', icon: 'admin_panel_settings', path: '/private/admin/sessions/list'});
    }

    if (this.action('admin.files.view')) {
      this.addToMenu(menu, {name: 'Administración', icon: 'security'},
      {name: 'Archivos', icon: 'folder', path: '/private/admin/files/list'});
    }

    return menu;
  }
}
