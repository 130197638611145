import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '../services/guards/login.guard';

import { PrivateComponent } from './private.component';
import { BusinessComponent } from './business-module/business.component';
import { SettingsComponent } from './settings-module/settings.component';
import { AdminComponent } from './admin-module/admin.component';

import { HomeComponent } from './home/home.component';

const privateRoutes:
Routes = [
    {
        path: 'private',
        component: PrivateComponent,
        canActivateChild: [ LoginGuard ],
        children: [
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'business',
                component: BusinessComponent,
                loadChildren: () => import ('./business-module/business.module').then( m => m.BusinessModule)
            },
            {
                path: 'settings',
                component: SettingsComponent,
                loadChildren: () => import ('./settings-module/settings.module').then( m => m.SettingsModule)
            },
            {
                path: 'admin',
                component: AdminComponent,
                loadChildren: () => import ('./admin-module/admin.module').then( m => m.AdminModule)
            }
        ]
    }
];

export const PRIVATE_ROUTES = RouterModule.forChild( privateRoutes );
