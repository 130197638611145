import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map, catchError, retry, timeout } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient ) {
  }
// -----------------------------------------------------------------------------
//  Post method
// -----------------------------------------------------------------------------
post( url: string, data: any ): Observable<any> {

  return this.http.post( `${ environment.endpoint.apiService }${ url }`, data )
  .pipe( retry(5), timeout(60000), map( (response: any) => {

      return response;

  }, catchError( err => {
    console.error(err);
    return throwError(err);
  })));

}
// -----------------------------------------------------------------------------
//  Get method
// -----------------------------------------------------------------------------
get( url: string ): Observable<any> {

  const requestUrl = `${ environment.endpoint.apiService }${ url }`;

  return this.http.get( requestUrl ).pipe( retry(5), timeout(60000), map( (response: any) => {

      return response;

    }, catchError( err => {
      console.error(err);
      return throwError(err);
    } )));
}
// -----------------------------------------------------------------------------
//  Get method
// -----------------------------------------------------------------------------
getList( url: string, options: any = [] ): Observable<any> {

  console.log(options);
  let requestUrl = `${ environment.endpoint.apiService }${ url }?`;

  if ( options.activeState ) { requestUrl = `${ requestUrl }activeState=${ options.activeState }&`; }
  if ( options.state ) { requestUrl = `${ requestUrl }state=${ options.state }&`; }
  if ( options.sort ) { requestUrl = `${ requestUrl }sort=${ options.sort }&`; }
  if ( options.order ) { requestUrl = `${ requestUrl }order=${ options.order }&`; }
  if ( options.page ) { requestUrl = `${ requestUrl }page=${ options.page + 1 }&`; }
  if ( options.pageSize ) { requestUrl = `${ requestUrl }perPage=${ options.pageSize }&`; }
  if ( options.search ) { requestUrl = `${ requestUrl }search=${ options.search }&`; }
  if ( options.filterBillingDay ) { requestUrl = `${ requestUrl }filterBillingDay=${ options.filterBillingDay }&`; }
  if ( options.force ) { requestUrl = `${ requestUrl }force=${ options.force }&`; }
  if ( options.sendmail ) { requestUrl = `${ requestUrl }sendmail=${ options.sendmail }&`; }
  if ( options.reconciled ) { requestUrl = `${ requestUrl }reconciled=${ options.reconciled }&`; }
  if ( options.paidOut! ) { requestUrl = `${ requestUrl }paidOut=${ options.paidOut }&`; }

  return this.http.get( requestUrl ).pipe( retry(5), timeout(60000), map( (response: any) => {

      return response;

    }, catchError( err => {

      console.error(err);
      return throwError(err);
    } )));
}
// -----------------------------------------------------------------------------
//  Delete method
// -----------------------------------------------------------------------------
delete( url: string ): Observable<any> {

  return this.http.delete( `${ environment.endpoint.apiService }${ url }`).pipe( retry(5), timeout(60000), map( (response: any) => {

      return response;

    }, catchError( err => {
      console.error(err);
      return throwError(err);
    } )));

}
// -----------------------------------------------------------------------------
//  Patch method
// -----------------------------------------------------------------------------
patch( url: string, data: any ): Observable<any> {

  return this.http.patch( `${ environment.endpoint.apiService }${ url }`, data ).pipe( retry(5), timeout(60000), map( (response: any) => {

      return response;

    }, catchError( err => {
      console.error(err);
      return throwError(err);
    } )));

}
// -----------------------------------------------------------------------------
//  Export data method
// -----------------------------------------------------------------------------
  exportData( url: string, authorization: string ): void {
    window.open( `${environment.endpoint.apiService}${ url }?Authorization=${authorization}`, '_self');
  }
// -----------------------------------------------------------------------------
//  Upload file
// -----------------------------------------------------------------------------
uploadFile( url: string,  file: File ): Observable<any> {

  const formData = new FormData();
  formData.append( 'file', file, file.name);

  return this.http.post( `${ environment.endpoint.apiService }${ url }`, formData, { reportProgress: true, observe: 'events' })
  .pipe( map( (event: any, err: any) => {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        const progress = Math.round(100 * event.loaded / event.total);
        console.log( progress );
        break;
      case HttpEventType.Response:
        if ( event ) {
          return event.body;
        }
        break;
      default:
        break;
    }

  }, catchError( err => {
    console.error(err);
    return throwError(err);
  })));

}
// -----------------------------------------------------------------------------
//  Post method
// -----------------------------------------------------------------------------
replaceFile( url: string,  file: File ): Observable<any> {

  const formData = new FormData();
  formData.append( 'file', file, file.name);

  return this.http.patch( `${ environment.endpoint.apiService }${ url }`, formData, { reportProgress: true, observe: 'events' })
  .pipe( map( (event: any, err: any) => {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        const progress = Math.round(100 * event.loaded / event.total);
        console.log( progress );
        break;
      case HttpEventType.Response:
        if ( event ) {
          return event.body;
        }
        break;
      default:
        break;
    }

  }, catchError( err => {
    console.error(err);
    return throwError(err);
  })));

}
}
