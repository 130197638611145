import { Injectable } from '@angular/core';
import { map, catchError, retry, timeout } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpService } from '../platform/http.service';
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  authorization = '';

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService,
    public securityService: SecurityService,
    ) { 
    this.authorization = this.securityService.dataSession.authorization;

  }
// -----------------------------------------------------------------------------
//  Create immovable
// -----------------------------------------------------------------------------
  createCondominium( data: any ): Observable<any> {

    return this.httpService.post( `/customers/condominium`,  data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {
      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getCondominiumList(  options: any = [] ): Observable<any> {

    return this.httpService.getList( `/customers/condominium`, options).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getCondominiumById( id: string): Observable<any> {

    return this.httpService.get( `/customers/condominium/${ id }`).pipe( map( (response: any) => {

      console.log('from service : ', response);
      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Update immovable
// -----------------------------------------------------------------------------
  updateCondominium( id: string, data: any ): Observable<any> {

    return this.httpService.patch( `/customers/condominium/${ id }`, data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  export Data
// -----------------------------------------------------------------------------
  exportData(): void{

    this.httpService.exportData(`/customers/condominium/export/bill`, this.authorization);

  }
// -----------------------------------------------------------------------------
//  export Data
// -----------------------------------------------------------------------------
  exportDataHouses(): void{

    this.httpService.exportData(`/customers/houses/export/bill`, this.authorization);

  }
// -----------------------------------------------------------------------------
//  Delete user
// -----------------------------------------------------------------------------
deleteCondominium( id: string ): Observable<any> {

  return this.httpService.delete( `/customers/condominium/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}







// -----------------------------------------------------------------------------
//  Create immovable
// -----------------------------------------------------------------------------
createHouses( data: any ): Observable<any> {

  return this.httpService.post( `/customers/houses`,  data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
getHousesList(  options: any = [] ): Observable<any> {

  return this.httpService.getList( `/customers/houses`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
getHousesById( id: string): Observable<any> {

  return this.httpService.get( `/customers/houses/${ id }`).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Update immovable
// -----------------------------------------------------------------------------
updateHouses( id: string, data: any ): Observable<any> {

  console.log(data);
  return this.httpService.patch( `/customers/houses/${ id }`, data ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}

// -----------------------------------------------------------------------------
//  Delete user
// -----------------------------------------------------------------------------
deleteHouses( id: string ): Observable<any> {

return this.httpService.delete( `/customers/houses/${ id }` ).pipe( map( (response: any) => {

  return response;

}, catchError( err => {

  return throwError(err);

} )));

}

// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
getSalesForToday(  options: any = [] ): Observable<any> {

  return this.httpService.getList( `/customers/salesfortoday`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}

}
