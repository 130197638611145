import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { RouterModule } from '@angular/router';
// // -----------------------------------------------------------------------------
// //  Libraries
// // -----------------------------------------------------------------------------
// import { QuillModule } from 'ngx-quill';
// import { ZXingScannerModule } from '@zxing/ngx-scanner';
// import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { MatSidenavModule } from '@angular/material';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatIconModule } from '@angular/material';
// import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
// import { MatExpansionModule } from '@angular/material/input';
// import { MatButtonModule } from '@angular/material';
// import { MatMenuModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';



// import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


import {MatListModule} from '@angular/material/list';
// import { MatCheckboxModule } from '@angular/material';
// import { MatRadioModule } from '@angular/material';
// import { MatStepperModule } from '@angular/material';
// import { MatTooltipModule } from '@angular/material';
// import { MatOptionModule, MatSelectModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// // -----------------------------------------------------------------------------
// //  Components
// // -----------------------------------------------------------------------------
// import { QrReaderComponent } from './components/qr-reader.component/qr-reader.component';
// import { CardPersonComponent } from './card-person/card-person.component';
// // -----------------------------------------------------------------------------
// //  Pages
// // -----------------------------------------------------------------------------
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SearchPolicyComponent } from './search-policy/search-policy.component';
import { DialogWindowComponent } from './dialog-window/dialog-window.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { SearchCustomerHouseComponent } from './search-customer-house/search-customer-house.component';
import { LoadImageComponent } from './load-image/load-image.component';

import { SharedModule } from '../shared/shared.module';


// import { MenuComponent } from './menu/menu.component';


// import { MapComponent } from './pages/map/map.component';
// import { UploadComponent } from './pages/upload/upload.component';
// import { UploadWindowComponent } from './pages/upload-window/upload-window.component';
// import { SearchProductComponent } from './pages/search-product/search-product.component';
// import { SearchCustomerComponent } from './pages/search-customer/search-customer.component';
// import { SearchPeopleComponent } from './pages/search-people/search-people.component';
// import { AlertComponent } from './alert/alert.component';
// import { DialogComponent } from './dialog/dialog.component';
// import { WriterComponent } from './writer/writer.component';
// import { Error404Component } from './error404/error404.component';
// import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
// import { BlockWindowComponent } from './pages/block-window/block-window.component';

@NgModule({
  imports: [

    SharedModule,
    // RouterModule,
    CommonModule,
    FlexLayoutModule,
    // ImageCropperModule,
    // ZXingScannerModule,
    FormsModule,
    ReactiveFormsModule,
    // QuillModule.forRoot(),
    NgxSpinnerModule,
    // MatSidenavModule,
    MatToolbarModule,
    // MatIconModule,
    // CdkScrollableModule,
    // MatListModule,
    // MatExpansionModule,
    MatInputModule,
    // MatButtonModule,
    // MatMenuModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatButtonModule,
    // FontAwesomeModule,
    // MatOptionModule, MatSelectModule, MatCheckboxModule,
    // MatRadioModule,
    // MatStepperModule,
    // MatTooltipModule,
    // MatProgressBarModule,
    ScrollingModule,
    MatDialogModule,
    MatButtonToggleModule
    // MatSnackBarModule
  ],
    declarations: [
      SearchPolicyComponent,
      DialogWindowComponent,
      SearchCustomerComponent,
      SearchCustomerHouseComponent,
      LoadImageComponent
      // NotificationsComponent,
      // MenuComponent,
      // BreadcrumbsComponent,
      // CardPersonComponent,
      // SearchProductComponent,
      // SearchCustomerComponent,
      // QrReaderComponent,
      // DialogComponent,
      // AlertComponent,
      // Error404Component,
      // UploadComponent,
      // WriterComponent,
      // MapComponent,
      // BlockWindowComponent,
      // UploadWindowComponent,
      // SearchPeopleComponent
    ],
    exports: [
      SearchPolicyComponent,
      DialogWindowComponent,
      SearchCustomerComponent,
      SearchCustomerHouseComponent,
      LoadImageComponent
      // NotificationsComponent,
      // MenuComponent,
      // BreadcrumbsComponent,
      // CardPersonComponent,
      // UploadComponent,
      // WriterComponent,
      // MapComponent,
      // SearchProductComponent,
      // SearchCustomerComponent,
      // QrReaderComponent,
      // BlockWindowComponent,
      // UploadWindowComponent,
      // SearchPeopleComponent
    ],
   entryComponents: [
    //  DialogComponent,
    //  AlertComponent
    ]
})

export class ComponentsModule { }

