import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpService } from '../platform/http.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService ) { }
// -----------------------------------------------------------------------------
//  Create immovable
// -----------------------------------------------------------------------------
  createBranchOffice( data: any ): Observable<any> {

    return this.httpService.post( `/business/branchoffice`,  data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getBranchOfficeList(  options: any = [] ): Observable<any> {

    return this.httpService.getList( `/business/branchoffice`, options).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getBranchOfficeById( id: string): Observable<any> {

    return this.httpService.get( `/business/branchoffice/${ id }`).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Update immovable
// -----------------------------------------------------------------------------
  updateBranchOffice( id: string, data: any ): Observable<any> {

    console.log(data);
    return this.httpService.patch( `/business/branchoffice/${ id }`, data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }

// -----------------------------------------------------------------------------
//  Delete user
// -----------------------------------------------------------------------------
deleteBranchOffice( id: string ): Observable<any> {

  return this.httpService.delete( `/business/branchoffice/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}

}
