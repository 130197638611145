<div class="dialog-component " fxLayout="column" fxLayoutAlign="center center">
    <div class="backcolor animate__animated animate__fadeIn"></div>
    <mat-card class="dialog-container mat-elevation-z4 animate__animated animate__bounceInUp">
        <mat-toolbar color="primary" class="mat-elevation-z0" style="min-height: 55px!important; height: 55px!important;">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon>error_outline</mat-icon>
                <span class="title">{{ title }}</span>
                <span class="spacer"></span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="editor-container">
            {{ text }}
        </div>
        <mat-dialog-actions>
            <button *ngFor="let item of buttons; let i = index" mat-flat-button [color]="i === 0 ? 'primary':''" (click)="clickBotton( i )">
                {{ item.label }}
            </button>
        </mat-dialog-actions>
    </mat-card>
</div>