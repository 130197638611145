import { Component } from '@angular/core';

import _dataApp from '../../../../package.json';


@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent {

  version = _dataApp.version;
  build = _dataApp.build;

  constructor() {

  }


}
