import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate, CanActivateChild {
// -----------------------------------------------------------------------------
//  Classe contruction
// -----------------------------------------------------------------------------
  constructor( private securityService: SecurityService,
               private router: Router ) {
  }
// -----------------------------------------------------------------------------
//  Valdate access
// -----------------------------------------------------------------------------
  canActivate(): boolean {
    if ( !this.securityService.isAuthenticated() ) {
      console.log('logout guard ok');

      return true;
    } else {

      console.log('logout guard block');

      this.router.navigate(['/private/home']);
      return false;
    }
  }
// -----------------------------------------------------------------------------
//  Valdate child access
// -----------------------------------------------------------------------------
  canActivateChild(): boolean {
    return this.canActivate();
}

}
