<div class="main-container desk-color">

    <div class="desk-image" [ngStyle]="{ 'opacity' : imageDesk.opacity }" *ngIf="imageDesk.active"></div>

    <div class="sidenav menu-color" [ngStyle]="{ 'width' : menuLeft }">
        <div class="bar-top-sidenav">
            <section>
                <div class="button-row poiter" (click)="goto2('/private/home')">
                    <img *ngIf="this.userPreference.menuType === MENU_MINI" class="min-logo animate__animated animate__bounceIn" src="../../assets/images/logos/icono.png">
                    <img *ngIf="this.userPreference.menuType === MENU_FULL" class="full-logo animate__animated animate__bounceIn" src="../../assets/images/logos/icono_full.png">
                </div>
            </section>
        </div>


        <div class="menu-container">
            <mat-accordion>
                <mat-expansion-panel class="menu-expansion user mat-elevation-z0">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" matTooltip="{{ userName }}" [matTooltipPosition]="'right'" [matTooltipDisabled]="!tooltips" matTooltipClass="tooltip">
                        <div class="select-bar"></div>
                        <mat-panel-title>
                            <mat-icon mat-list-icon><img class="image-user" [src]="pictureUser"></mat-icon>
                            <span class="desc">{{ userName }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row-submenu" fxLayout="row" fxLayoutAlign="start center" (click)="profile()" matTooltip="Perfil" [matTooltipPosition]="'right'" [matTooltipDisabled]="!tooltips" matTooltipClass="tooltip">
                        <mat-icon mat-list-icon>account_circle</mat-icon>
                        <span class="sub-desc">Perfil</span>
                    </div>
                    <div class="row-submenu" fxLayout="row" fxLayoutAlign="start center" (click)="closeSession()" matTooltip="Cerrar sesión" [matTooltipPosition]="'right'" [matTooltipDisabled]="!tooltips" matTooltipClass="tooltip">
                        <mat-icon mat-list-icon>power_settings_new</mat-icon>
                        <span class="sub-desc">Cerrar sesión</span>
                    </div>
                </mat-expansion-panel>
                <ng-container *ngFor="let itemMenu of folders; let i = index">
                    <mat-expansion-panel class="menu-expansion mat-elevation-z0">
                        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" (click)="goto2( itemMenu.path )" matTooltip="{{itemMenu.name}}" [matTooltipPosition]="'right'" [matTooltipDisabled]="!tooltips" matTooltipClass="tooltip">
                            <div class="select-bar"></div>
                            <mat-panel-title>
                                <mat-icon mat-list-icon>{{ itemMenu.icon }}</mat-icon>
                                <span class="desc">{{ itemMenu.name }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ng-container *ngFor="let itemSubMenu of itemMenu.options; let j = index">
                            <div class="row-submenu" fxLayout="row" fxLayoutAlign="start center" (click)="goto2( itemSubMenu.path )" matTooltip="{{itemSubMenu.name}}" [matTooltipPosition]="'right'" [matTooltipDisabled]="!tooltips" matTooltipClass="tooltip">
                                <mat-icon mat-list-icon>{{ itemSubMenu.icon }}</mat-icon>
                                <span class="sub-desc">{{ itemSubMenu.name }}</span>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>

        <div class="bar-bottom-sidenav">
            <section>
                <ng-container *ngIf="!typeDevicesMobile">
                    <div class="button-row">
                        <button mat-button class="example-icon" (click)="selectMenu()">
                          <mat-icon>menu</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </section>
        </div>
    </div>

    <div class="content" [ngStyle]="{ 'margin-left' : menuLeft, 'width': menuWidth }">
        <router-outlet></router-outlet>
        <span class="version">v{{ version }}-{{ build }} |  <b>{{ envName }}</b> server</span>
    </div>


    <button *ngIf="typeDevicesMobile && (userPreference.menuType === MENU_HIDE || MENU_FULL)" mat-button class="mobile-menu-botton" (click)="selectMenu()">
    <mat-icon>menu</mat-icon>
  </button>


</div>

<ng-template appLoader></ng-template>