import { Component, OnInit, NgZone, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { SecurityService } from '../services/platform/security.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import DataApp from '../../../package.json';
import { AlertService, LoaderService, LocalStorageService } from 'src/app/services/services.index';
import { LoaderDirective } from './loader.directive';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import _dataApp from '../../../package.json';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})

export class PrivateComponent  implements OnInit, OnDestroy {

  @ViewChild(LoaderDirective, { static: true })
  loaderDirective: LoaderDirective;
  private destroySubject = new Subject();

  version = _dataApp.version;
  build = _dataApp.build;

  dataApp = DataApp;
  sessionStatusSubscription: Subscription;
  singOutSubscription: Subscription;
  idComponent = 'PrivateComponent';
  userPreference = {
    menuType: 0
  };
  typeDevicesMobile: boolean;

  menuLeft = '0px';
  menuWidth = 'auto';
  MENU_HIDE = 1;
  MENU_MINI = 3;
  MENU_FULL = 2;

  imageDesk = { active: true, opacity: 0.2 };

  envName = environment.name;

  folders: any[];
  tooltips = true;
  pictureUser = '../../assets/images/placeholder/no_user.png';
  userName = 'Sin nombre';
// -----------------------------------------------------------------------------
//  Class contructor
// -----------------------------------------------------------------------------
  constructor( private securityService: SecurityService,
               private alertService: AlertService,
               private router: Router,
               private localStorageService: LocalStorageService,
               private ngZone: NgZone,
               private loaderService: LoaderService ){

      this.folders = this.securityService.getMenu();
      this.securityService.getCurrentProfile().subscribe( userData => {
        // this.userName = `${ userData.profile.name.firstName }
        // ${ userData.profile.name.lastNamePaternal} ${ userData.profile.name.lastNameMaternal}`;
        this.userName = `${ userData.profile.name.firstName }`;
        this.userName = this.userName === '' ? 'Sin nombre' : this.userName;
        this.pictureUser = userData.profile.picture === '' ? '../../../assets/images/placeholder/no_user.png' : `${ userData.profile.picture }?Authorization=${ this.securityService.dataSession.authorization }`;
      });
      this.securityService.updateCurrentProfile();


      if ( window.innerWidth > 600) {
        this.typeDevicesMobile = false;
        const data = this.localStorageService.get( this.idComponent );
        if ( data ) {
          this.userPreference = data;
        } else {
          this.userPreference.menuType = this.MENU_FULL;
        }
      } else {
        this.typeDevicesMobile = true;
        this.userPreference.menuType = this.MENU_HIDE;
      }
      this.validateScreen('load');
      window.onresize = (e: any) => this.validateScreen();

  }
// -----------------------------------------------------------------------------
//  Init object
// -----------------------------------------------------------------------------
  ngOnInit(): void {
    this.sessionStatusSubscription = this.securityService.sessionStatus().subscribe( response => {
    }, err => {
      this.securityService.singOut();
    });

    const viewContainerRef = this.loaderDirective.viewContainerRef;
    this.loaderService.component
      .pipe(
        takeUntil(this.destroySubject),
        mergeMap(componentData =>

          this.loaderService.loadComponent(viewContainerRef, componentData)

        )
      ).subscribe();

  }
// -----------------------------------------------------------------------------
//  Destroy object
// -----------------------------------------------------------------------------
  ngOnDestroy(): void {
    if (this.sessionStatusSubscription) {
      this.sessionStatusSubscription.unsubscribe();
    }
    if (this.singOutSubscription) {
      this.singOutSubscription.unsubscribe();
    }

    this.destroySubject.next();
    this.destroySubject.complete();

  }
// -----------------------------------------------------------------------------
//  Validate screen width
// -----------------------------------------------------------------------------
  validateScreen( s: string = ''): void {
    this.ngZone.run(() => {
      if ( window.innerWidth > 600) {
         this.typeDevicesMobile = false;
         if (s === '') {
           this.userPreference.menuType  = this.MENU_MINI;
         }
         this.changeMenuStyle();
      } else {
          this.typeDevicesMobile = true;
          this.userPreference.menuType  = this.MENU_HIDE;
          this.changeMenuStyle();
      }
    });
  }
// -----------------------------------------------------------------------------
//  Select menu style
// -----------------------------------------------------------------------------
  selectMenu(): void {
    if (this.typeDevicesMobile) {
      if ( this.userPreference.menuType  === this.MENU_FULL ) {
        this.userPreference.menuType  = this.MENU_HIDE;
      } else {
        this.userPreference.menuType  = this.MENU_FULL;
      }
    } else {
      if ( this.userPreference.menuType  === this.MENU_FULL ) {
        this.userPreference.menuType  = this.MENU_MINI;
      } else {
        this.userPreference.menuType  = this.MENU_FULL;
      }
    }
    this.localStorageService.save( this.idComponent, this.userPreference );
    this.changeMenuStyle();
  }
// -----------------------------------------------------------------------------
//  Change menu style
// -----------------------------------------------------------------------------
  changeMenuStyle(): void {
    this.menuWidth = '100%';
    if (this.typeDevicesMobile) {
      switch ( this.userPreference.menuType  ) {
        case this.MENU_HIDE:
          this.menuLeft = '0px';
          this.tooltips = false;
          break;
        case this.MENU_FULL:
            this.menuLeft = `${window.innerWidth * 1.0}px`;
            this.tooltips = false;
            break;
        default:
      }
    } else {
      this.menuWidth = 'auto';
      switch ( this.userPreference.menuType ) {
        case this.MENU_HIDE:
          this.menuLeft = '0px';
          this.tooltips = false;
          break;
        case this.MENU_FULL:
          this.menuLeft = '250px';
          this.tooltips = false;
          break;
        case this.MENU_MINI:
          this.menuLeft = '56px';
          this.tooltips = true;
          break;
        default:
      }
    }
  }
// -----------------------------------------------------------------------------
//  Goto option menu
// -----------------------------------------------------------------------------
  goto2( path: string ): void {
    if (path && path !== '') {
      this.router.navigate([path]);
      if ( this.typeDevicesMobile ) {
        this.selectMenu();
      }
    }
  }
// -----------------------------------------------------------------------------
//  Goto profile
// -----------------------------------------------------------------------------
  profile(): void {
    if ( this.typeDevicesMobile ) {
      this.selectMenu();
    }
    this.router.navigate(['/private/settings/users', this.securityService.getUserId()]);
  }
// -----------------------------------------------------------------------------
//  Close session
// -----------------------------------------------------------------------------
  closeSession(): void {
    const $subscription = this.securityService.singOut().subscribe( response => {
      $subscription.unsubscribe();
      this.alertService.create({ type: 'ok', title: 'Sesión finalizada correctamente, adios' });
    }, err => {
      this.securityService.singOut();
    });
  }

}
