<div class="search-component " fxLayout="column" fxLayoutAlign="center center">
    <mat-card class="search-container mat-elevation-z4 animate__animated animate__bounceInUp" [ngClass]="{'full-window': fullWindow}">
        <mat-toolbar color="primary" class="mat-elevation-z0" style="min-height: 55px!important; height: 55px!important;">

            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon>privacy_tip</mat-icon>
                <span fxHide.lt-md="true" class="title">{{ title }}</span>
                <span class="spacer"></span>
                <mat-form-field class="form-fiel-search">
                    <input #search matInput [(ngModel)]="wordToFind" [disabled]="fetchRunning" (keydown)="onKeydown($event)">
                    <button mat-icon-button matSuffix (click)="searchText()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </mat-toolbar-row>

        </mat-toolbar>
        <div class="editor-container">
            <mat-selection-list [(ngModel)]="dataItemsSelected" class="selection-list-container" checkboxPosition='before' multiple='true' (selectionChange)="selectItem()">
                <cdk-virtual-scroll-viewport itemSize="80" style="height: 100%">
                    <mat-list-option disableRipple="true" style="height: 100%!important; margin:0px 0px; padding: 0px; height:80px!important; border-bottom: 1px solid rgb(228, 228, 228)!important" *cdkVirtualFor="let item of searchResults" [value]="item">
                        <h3 matLine>{{ item.module }}</h3>
                        <h3 matLine>{{ item.section }}</h3>
                        <h4 matLine>{{item.description}}</h4>
                    </mat-list-option>
                </cdk-virtual-scroll-viewport>
            </mat-selection-list>
        </div>
        <mat-dialog-actions>
            <button mat-flat-button color="primary" (click)="acceptBotton()" [disabled]="dataItemsSelected.length === 0">OK</button>
            <button mat-flat-button (click)="cancelBotton()">Cancelar</button>
        </mat-dialog-actions>
    </mat-card>
</div>