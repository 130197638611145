import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map, catchError, retry, timeout } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient ) {
  }

// -----------------------------------------------------------------------------
//  Get method
// -----------------------------------------------------------------------------
  getlocation( text: string ): Observable<any> {

    const requestUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${ text }.json?access_token=pk.eyJ1IjoieGlkZGVuIiwiYSI6ImNrNG44OXY1YzI0ZDkza2xiY2VmemE4YTUifQ.P6L_fojAaq5DakX5yDgUpg`;

    return this.http.get( requestUrl ).pipe( retry(5), timeout(60000), map( (response: any) => {

        return response;

      }, catchError( err => {
        console.error(err);
        return throwError(err);
      } )));
  }
}
