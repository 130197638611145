import { Component, AfterViewInit } from '@angular/core';
import { SecurityService } from 'src/app/services/services.index';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {

  userName = '';
  time = new Date();
  greatings = '...';
  constructor( private securityService: SecurityService ) {

    this.securityService.getCurrentProfile().subscribe( userData => {
      this.userName = ` ${ userData.profile.name.firstName }`;
      this.userName = this.userName === ' ' ? '' : this.userName;
    });
    this.securityService.updateCurrentProfile();

    this.setGretting();
  }

  ngAfterViewInit(): void {
    setInterval(() => {
      this.time = new Date();
      this.setGretting();
   }, 1000 * 60);
  }

  setGretting(): void {
    const h = this.time.getHours();
    if ( h >= 0 && h < 12 ) {
      this.greatings = `¡Buenos días${ this.userName }!`;
    } else if  ( h >= 12 && h < 19 ) {
      this.greatings = `¡Buenas tardes${ this.userName }!`;
    } else {
      this.greatings = `¡Buenas noches${ this.userName }!`;
    }
  }




}
