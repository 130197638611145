import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SecurityService } from '../platform/security.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor( private securityService: SecurityService ) {
      console.log('this.securityService.dataSession.authorization: ', this.securityService.dataSession.authorization);
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',
      this.securityService.dataSession.authorization
    );



    console.log('intercept : ', req.url);
    const newReq = req.clone({
      headers,
    });

    return next.handle( newReq ).pipe(
      catchError( this.getError )
    );
  }

  getError( error: HttpErrorResponse ): Observable<HttpEvent<any>> {
    console.log(error);
    return throwError( error );
  }

}

