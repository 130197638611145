import { Component } from '@angular/core';
import { EventEmitter, HostListener, Input, Output} from '@angular/core';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { FilesService } from '../../services/platform/files.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})

export class UploadComponent {

  @Input() id = '';
  @Input() locationResource = '';
  @Input() urlResource = '';
  @Input() adjustImage = false;
  @Output() fileLoaded: EventEmitter<any> = new EventEmitter();

  target: HTMLInputElement;
  onDropZone = false;
  progressUpload = 0;
  statusDesc = '';
  description = '';

  newDocument = true;
  text: string;
  imageChangeBase64: any = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCroper = false;
  showPrevImage = false;
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private resourceService: FilesService,
               private spinner: NgxSpinnerService ) {
    if ( this.text === '' ) {
      this.text = 'Arrastra tu archivo aqui o da click para buscarlo';
    }
  }
// -----------------------------------------------------------------------------
//  File changed
// -----------------------------------------------------------------------------
  fileChanged(e: Event): void {
    if ( this.adjustImage ) {
      this.imageChangedEvent = e;
      this.showCroper = true;
    } else {
      this.target = e.target as HTMLInputElement;
      this.sendFileToServer(this.target.files[0]);
    }
  }
// -----------------------------------------------------------------------------
//  Send file to server
// -----------------------------------------------------------------------------
  sendFileToServer( file: File ): void {
      this.spinner.show();

      if ( this.urlResource === '' ) {
        this.resourceService.upload( file, this.locationResource ).subscribe( response => {
          this.spinner.hide();

          if ( response ) {
            this.fileLoaded.emit( { urlResource: response.businessResponse.urlResource, id: this.id  });
          }
        });

      } else {

        const a = this.urlResource.split('/');
        this.urlResource = a[a.length - 1].split('.')[0];

        this.resourceService.replace( file, this.urlResource ).subscribe( response => {
          this.spinner.hide();

          if ( response ) {
            this.fileLoaded.emit( { urlResource: response.businessResponse.urlResource, id: this.id });
          }
        }, (err: any) => {
            this.fileLoaded.emit( { urlResource: ''});
        });
      }



  }
// -----------------------------------------------------------------------------
//  Send image croped file to server
// -----------------------------------------------------------------------------
  sendImage(): void {
    fetch(this.croppedImage)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], 'File_name.png', { type: 'image/png' });
      this.sendFileToServer( file );

      this.showCroper = false;
      this.showPrevImage = false;
      this.croppedImage = '';
      this.imageChangedEvent = '';
      this.imageChangeBase64 = '';

    });
  }
// -----------------------------------------------------------------------------
//  Cance sending file to server
// -----------------------------------------------------------------------------
  cancelImage(): void {
    this.showCroper = false;
    this.showPrevImage = false;
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.imageChangeBase64 = '';
  }
// -----------------------------------------------------------------------------
//  Image cropped
// -----------------------------------------------------------------------------
imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
}
// -----------------------------------------------------------------------------
//  Image loaded
// -----------------------------------------------------------------------------
imageLoaded(): void {
}
// -----------------------------------------------------------------------------
//  Cropeed redy
// -----------------------------------------------------------------------------
cropperReady(): void {
  console.log('cropperReady');
  this.showPrevImage = true;
}
// -----------------------------------------------------------------------------
//  Error load image
// -----------------------------------------------------------------------------
loadImageFailed(): void {
  console.log('loadImageFailed');
    // show message
}
// -----------------------------------------------------------------------------
//  Host listeners
// -----------------------------------------------------------------------------
  @HostListener('dragenter', ['$event'])
  public onDragEnter( event: any ): void {
    this.onDropZone = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave( event: any ): void {
    this.onDropZone = false;
  }

  @HostListener('dragover', ['$event'])
  public onDragOver( event: any ): void {
    const transfer = this._getTransfer( event );
    transfer.dropEffect = 'copy';
    this._preventAndStop( event );
    this.onDropZone = true;
  }

  @HostListener('drop', ['$event'])
  public onDrop( event: any ): void {
    this.target = this._getTransfer( event );
    if ( !this.target ) {
      return;
    }

    this.onDropZone = false;
    this._preventAndStop( event );

    if ( this.adjustImage ) {
      const myReader = new FileReader();
      myReader.onloadend = (e) => {
        this.imageChangeBase64 = myReader.result;
      };
      myReader.readAsDataURL(this.target.files[0]);
      this.showCroper = true;
    } else {
      this.sendFileToServer(this.target.files[0]);
    }
  }
// -----------------------------------------------------------------------------
//  Get transfer
// -----------------------------------------------------------------------------
  private _getTransfer( event: any ): any {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }
// -----------------------------------------------------------------------------
//  Preven and stop
// -----------------------------------------------------------------------------
  private _preventAndStop( event: any ): any {
    event.preventDefault();
    event.stopPropagation();
  }

}
