import { Injectable } from '@angular/core';
import { map, catchError, retry, timeout } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpService } from './http.service';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class FilesService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService ) { }
// -----------------------------------------------------------------------------
//  delete resource
// -----------------------------------------------------------------------------
delete( id: string ): Observable<any> {
  return this.httpService.delete( `/files/${ id }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Upload resource
// -----------------------------------------------------------------------------
upload( file: File, locationResource: string ): Observable<any> {

  return this.httpService.uploadFile( `/files/${ locationResource }`, file ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Upload resource remplacing
// -----------------------------------------------------------------------------
replace( file: File, id: string ): Observable<any> {

  return this.httpService.replaceFile( `/files/${ id }`, file ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get files list
// -----------------------------------------------------------------------------
getList( options: any = [] ): Observable<any> {

  return this.httpService.getList( `/files/data/list`, options).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
}

