<div class="file-load-component">
    <div class="file-load">
        <label for="filesInput">
            <div class="drop-zone" [ngClass]="{ 'file-over': onDropZone }"></div>
        </label>
        <div fxLayout="column" fxLayoutAlign="center center" class="no_files">
            <i class="material-icons">
                cloud_upload
                </i>
        </div>
    </div>
</div>
<input style="position: absolute;" id="filesInput" type="file" (change)="fileChanged($event)" name="files" accept="*/*" multiple>
<ng-container *ngIf="showCroper">
    <div class="search-component " fxLayout="column" fxLayoutAlign="center center">
        <mat-card class="search-container mat-elevation-z4 animate__animated animate__bounceInUp">
            <mat-toolbar color="primary" class="mat-elevation-z0" style="min-height: 55px!important; height: 55px!important;">
                <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-evenly center">
                    <mat-icon>privacy_tip</mat-icon>
                    <span class="title">AJUSTE DE IMAGEN</span>
                    <span class="spacer"></span>
                </mat-toolbar-row>
            </mat-toolbar>
            <div class="editor-container">
                <mat-card-content class="container-image">
                    <image-cropper [imageBase64]="imageChangeBase64" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="3 / 3" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                    <ng-container *ngIf="showPrevImage">
                        <img class="previo mat-elevation-z5" [src]="croppedImage" />
                    </ng-container>
                </mat-card-content>
            </div>
            <mat-dialog-actions>
                <button mat-flat-button color="primary" (click)="sendImage()">Subir</button>
                <button mat-flat-button (click)="cancelImage()">Cancelar</button>
            </mat-dialog-actions>
        </mat-card>
    </div>
</ng-container>