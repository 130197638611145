import { Component, ViewChild, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgZone} from '@angular/core';
import { filter } from 'rxjs/operators';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { AlertService } from '../../services/services.index';
import { Observable } from 'rxjs';
import { LoaderService } from '../../services/services.index';
import { CustomersService } from '../../services/business/customers.service';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})

export class SearchCustomerComponent  implements OnInit {

  @ViewChild(CdkVirtualScrollViewport, { static: true }) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('search', { static: true }) searchElement: ElementRef;


  customerType = '1';

  multiple = true;
  title = 'CLIENTES';
  wordToFind = '';
  fullWindow = false;
  fetchRunning = false;
  searchPageNumber: number;
  searchResults: Array<any>;
  pagesize = 50;
  resetSearchBtn = false;
  dataItemsSelected = [];
  dataItemsSelectedOne = null;

  authorization = '';


  residentselect = false;
  residents: any[] = [];

  dataItemsSelectedApartment = [];


  typeResidence = 2;

  type = '';
  preventSingleClick = false;
  timer: any;
  delay: number;
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
constructor( private loaderService: LoaderService,
             private customerService: CustomersService,
             private scrollDispatcher: ScrollDispatcher,
             private alertService: AlertService,
             private zone: NgZone ) {

  this.loaderService.componentRequest.subscribe( data => {
    this.type = data.type;
  });


  console.log('buscando clientes: ', this.type);

  if ( this.type === 'condominium' ) {
    this.title = 'CLIENTES | CASAS';
    this.customerType = '1';
  } else {
    this.title = 'CLIENTES | CONDOMINIOS';
    this.customerType = '2';
  }

  this.searchPageNumber = 0;
  this.searchResults = [];
  this.dataItemsSelected = [];
}
// -----------------------------------------------------------------------------
//  Init object
// -----------------------------------------------------------------------------
ngOnInit(): void {
  this.nextSearchPage(this.searchPageNumber);

  this.searchElement.nativeElement.focus();
  this.scrollDispatcher.scrolled().pipe(
    filter(event => this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength() && !this.fetchRunning)
  ).subscribe(event => {
    this.searchPageNumber++;
    this.nextSearchPage(this.searchPageNumber);
  });
  this.virtualScroll.scrollToIndex(0, 'smooth');
}
// -----------------------------------------------------------------------------
//  Request next page
// -----------------------------------------------------------------------------
nextSearchPage(pageNumber: number): void {
  this.fetchRunning = true;
  this.getData(pageNumber).subscribe((response) => {
    this.zone.run( () => {
      this.searchResults = this.searchResults.concat(response.businessResponse.items);
      this.fetchRunning = false;
      if ( this.searchResults.length === 0 ) {
        this.alertService.create({ type: 'info', title: 'No hay clientes, verifica tu busqueda' });
      }
    });
  }, err => { this.processError(err); });
}
// -----------------------------------------------------------------------------
//  Get data
// -----------------------------------------------------------------------------
  getData(pageNumber): Observable<any> {
    const options = {
      sort: 'module',
      order: 'asc',
      page: pageNumber,
      pageSize: this.pagesize,
      activeState: 'active',
      search: this.wordToFind
    };

    if ( this.customerType === '2' ) {
      return this.customerService.getCondominiumList( options );
    } else {
      return this.customerService.getHousesList( options );
    }
  }



  changeCustomerType(): void {
    console.log( this.customerType );
    this.searchText();
  }
// -----------------------------------------------------------------------------
//  Run searching
// -----------------------------------------------------------------------------
searchText(): void {
      this.resetSearchBtn = true;
      this.virtualScroll.scrollToIndex(0, 'smooth');
      this.searchPageNumber = 0;
      this.searchResults = [];
      this.dataItemsSelected = [];
      this.nextSearchPage(this.searchPageNumber);
  }
// -----------------------------------------------------------------------------
//  Cancel searching
// -----------------------------------------------------------------------------
cancelSearchInput(): void {
    this.searchText();
    this.resetSearchBtn = false;
  }
// -----------------------------------------------------------------------------
//  Run searching
// -----------------------------------------------------------------------------
onKeydown(event: any): void {
  if (event.key === 'Enter') {
    this.searchText();
  }
}
// -----------------------------------------------------------------------------
//  Select item action
// -----------------------------------------------------------------------------
selectItem(): void {
}
// -----------------------------------------------------------------------------
//  Accept botton
// -----------------------------------------------------------------------------
acceptBotton(): void {

  this.dataItemsSelectedOne = this.dataItemsSelected[0];

  if ( this.customerType === '2' ) {
    // if ( this.dataItemsSelected[0].typeResidence === 2 ) {
      this.residents =  this.dataItemsSelectedOne.residents;
      this.residentselect = true;
    // console.log(this.dataItemsSelected);
    // console.log(this.residents);
  } else {
    this.loaderService.sendData( this.dataItemsSelectedOne );
  }
}
// -----------------------------------------------------------------------------
//  Cancel botton
// -----------------------------------------------------------------------------
cancelBotton(): void {
  this.loaderService.sendData( null );
  this.loaderService.closeView();
}
// -----------------------------------------------------------------------------
//  Select item action
// -----------------------------------------------------------------------------
selectItemApartment(): void {
}
// -----------------------------------------------------------------------------
//  Accept botton
// -----------------------------------------------------------------------------
acceptBottonApartment(): void {
  // this.loaderService.sendData( this.dataItemsSelected );

  this.residentselect = false;

  // console.log(this.dataItemsSelectedApartment);
  // this.dataItemsSelectedOne.residents = [];
  this.dataItemsSelectedOne.residents = this.dataItemsSelectedApartment[0];
  this.loaderService.sendData( this.dataItemsSelectedOne );
}
// -----------------------------------------------------------------------------
//  Cancel botton Apartment
// -----------------------------------------------------------------------------
cancelBottonApartment(): void {
  this.residentselect = false;
}






pad(num: number, size: number): string {
  let s = num + '';
  while ( s.length < size) {  s = '0' + s; }
  return s;
}
// -----------------------------------------------------------------------------
//  Processes Error from backend
// -----------------------------------------------------------------------------
processError( err ): void {
  this.fetchRunning = false;
  switch ( err ) {
    default:
      this.alertService.create({ type: 'error', title: 'Servicio no diponible, intenta más tarde' });
      break;
  }
}

// -----------------------------------------------------------------------------
//  Single click
// -----------------------------------------------------------------------------
singleClick(): void {
  this.preventSingleClick = false;
  const delay = 200;
  this.timer = setTimeout(() => {
    if (!this.preventSingleClick) {
      // alert('Single Click Event');
    }
  }, delay);
}
// -----------------------------------------------------------------------------
//  Double click
// -----------------------------------------------------------------------------
  doubleClick( ): void {
    this.preventSingleClick = true;
    clearTimeout(this.timer);

    if (this.dataItemsSelected.length > 0) {
      this.acceptBotton( );
    }
  }
// -----------------------------------------------------------------------------
//  Single click
// -----------------------------------------------------------------------------
  singleClickApartment(): void {
    this.preventSingleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSingleClick) {
        // alert('Single Click Event');
      }
    }, delay);
  }
// -----------------------------------------------------------------------------
//  Double click
// -----------------------------------------------------------------------------
  doubleClickApartment( ): void {
    this.preventSingleClick = true;
    clearTimeout(this.timer);
    if (this.dataItemsSelectedApartment.length > 0) {
      this.acceptBottonApartment( );
    }
  }

}
