<div class="search-component " fxLayout="column" fxLayoutAlign="center center">
    <mat-card class="search-container mat-elevation-z4 animate__animated animate__bounceInUp" [ngClass]="{'full-window': fullWindow}">
        <mat-toolbar color="primary" class="mat-elevation-z0" style="min-height: 55px!important; height: 55px!important;">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon>privacy_tip</mat-icon>
                <span class="title">{{ title }}</span>
                <span class="spacer"></span>

                <!-- <mat-button-toggle-group name="fontStyle" [(ngModel)]="customerType" (change)="changeCustomerType()">
                    <mat-button-toggle value="1">CASAS</mat-button-toggle>
                    <mat-button-toggle value="2">CONDOMINIOS</mat-button-toggle>
                </mat-button-toggle-group> -->

                <span class="spacer"></span>

                <mat-form-field class="form-fiel-search">
                    <input #search matInput [(ngModel)]="wordToFind" [disabled]="fetchRunning" (keydown)="onKeydown($event)">
                    <button mat-icon-button matSuffix (click)="searchText()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="editor-container">

            <!--  -->

            <mat-selection-list [(ngModel)]="dataItemsSelected" class="selection-list-container" checkboxPosition='before' multiple='false' (selectionChange)="selectItem()" (click)="singleClick()" (dblclick)="doubleClick( )">
                <cdk-virtual-scroll-viewport itemSize="40" style="height: 100%">
                    <mat-list-option disableRipple="true" style="margin:0px 0px; padding: 0px; height:70px!important; border-bottom: 1px solid rgb(228, 228, 228)!important" *cdkVirtualFor="let item of searchResults" [value]="item">
                        <h3 matLine><b>{{ pad(item.codeCustomer, 5) }}</b>: {{ item.name.firstName }} {{ item.name.lastNamePaternal }} {{ item.name.lastNameMaternal }}, <span *ngIf="item.contact.phone!==''">tel. {{ item.contact.phone }}, </span> </h3>
                        <h3 matLine>
                            {{ item.address.street }} {{ item.address.outdoorNumber }} <span *ngIf="item.name.interiorNumber!==''">int. {{ item.address.interiorNumber }}, </span> Col. {{ item.address.neighbourhood }}, Municipio {{ item.address.municipality
                            }}, C.P. {{ item.address.postalCode }}, {{ item.address.entity }}, {{ item.address.country }}</h3>
                    </mat-list-option>
                </cdk-virtual-scroll-viewport>
            </mat-selection-list>
        </div>
        <mat-dialog-actions>
            <button mat-flat-button color="primary" (click)="acceptBotton()" [disabled]="dataItemsSelected.length === 0">OK</button>
            <button mat-flat-button (click)="cancelBotton()">Cancelar</button>
        </mat-dialog-actions>
    </mat-card>
</div>




<div *ngIf="residentselect" class="search-component" style="background: none;" fxLayout="column" fxLayoutAlign="center center">
    <mat-card class="search-container mat-elevation-z0 animate__animated animate__bounceIn" [ngClass]="{'full-window': fullWindow}">
        <mat-toolbar color="primary" class="mat-elevation-z0" style="min-height: 55px!important; height: 55px!important;">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon>privacy_tip</mat-icon>
                <span class="title">RESIDENTES</span>
                <span class="spacer"></span>
                <mat-form-field class="form-fiel-search">
                    <input #search matInput [(ngModel)]="wordToFind" [disabled]="fetchRunning" (keydown)="onKeydown($event)">
                    <button mat-icon-button matSuffix (click)="searchText()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="editor-container">
            <mat-selection-list [(ngModel)]="dataItemsSelectedApartment" class="selection-list-container" checkboxPosition='before' multiple='false' (selectionChange)="selectItemApartment()" (click)="singleClickApartment()" (dblclick)="doubleClickApartment( )">
                <mat-list-option *ngFor="let item of residents" disableRipple="true" style="margin:0px 0px; padding: 0px; height:40px!important; border-bottom: 1px solid rgb(228, 228, 228)!important" [value]="item">
                    <h3 matLine><b>Depto {{ item.apartment }}</b> - {{ item.name.firstName }} {{ item.name.lastNamePaternal }} {{ item.name.lastNameMaternal }}<span *ngIf="item.contact.phone!==''">, tel. {{ item.contact.phone }}, </span> </h3>
                </mat-list-option>
            </mat-selection-list>
        </div>
        <mat-dialog-actions>
            <button mat-flat-button color="primary" (click)="acceptBottonApartment()" [disabled]="dataItemsSelectedApartment.length === 0">OK</button>
            <button mat-flat-button (click)="cancelBottonApartment()">Cancelar</button>
        </mat-dialog-actions>
    </mat-card>
</div>