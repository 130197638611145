import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PUBLIC_ROUTES } from './public.routes';

import { FlexLayoutModule } from '@angular/flex-layout';

import { PublicComponent } from './public.component';
import { GatewayComponent } from './gateway/gateway.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    PUBLIC_ROUTES,
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    FlexLayoutModule
    ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  declarations: [
    PublicComponent,
    GatewayComponent,
  ]
})

export class PublicModule { }
