<div class="search-component " fxLayout="column" fxLayoutAlign="center center">
    <mat-card class="search-container mat-elevation-z4 animate__animated animate__bounceInUp" [ngClass]="{'full-window': fullWindow}">
        <mat-toolbar color="primary" class="mat-elevation-z0" style="min-height: 55px!important; height: 55px!important;">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon>add_photo_alternate</mat-icon>
                <span class="title">{{ title }}</span>
                <span class="spacer"></span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="editor-container">

            <app-upload class="picture-load" [adjustImage]="true" [locationResource]="locationResource" [urlResource]="urlResource" (fileLoaded)="fileLoaded($event)"></app-upload>

        </div>
        <mat-dialog-actions>
            <!-- <button mat-flat-button color="primary" (click)="acceptBotton()" [disabled]="false">OK</button> -->
            <button mat-flat-button (click)="cancelBotton()">Cancelar</button>
        </mat-dialog-actions>
    </mat-card>
</div>