import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PRIVATE_ROUTES } from './private.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PrivateComponent } from './private.component';
import { BusinessComponent } from './business-module/business.component';
import { SettingsComponent } from './settings-module/settings.component';
import { AdminComponent } from './admin-module/admin.component';
import { HomeComponent } from './home/home.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

import { LoaderDirective } from './loader.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';


@NgModule({
  imports: [
    PRIVATE_ROUTES,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDividerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  declarations: [
    PrivateComponent,
    BusinessComponent,
    SettingsComponent,
    AdminComponent,

    HomeComponent,

    LoaderDirective
  ]
})

export class PrivateModule { }
