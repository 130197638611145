import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate, CanActivateChild {
// -----------------------------------------------------------------------------
//  Classe contruction
// -----------------------------------------------------------------------------
  constructor( private securityService: SecurityService ) {
  }
// -----------------------------------------------------------------------------
//  Valdate access
// -----------------------------------------------------------------------------
  canActivate(): boolean {
    if ( this.securityService.isAuthenticated() ) {

      console.log('login guard ok');
      return true;
    } else {
      console.log('login guard block');
      this.securityService.singOut();

      return false;
    }
  }
// -----------------------------------------------------------------------------
//  Valdate child access
// -----------------------------------------------------------------------------
  canActivateChild(): boolean {
    return this.canActivate();
}

}
