import { RouterModule, Routes } from '@angular/router';
import { LogoutGuard } from '../services/guards/logout.guard';

import { PublicComponent } from './public.component';
import { GatewayComponent } from './gateway/gateway.component';

const publicRoutes:
Routes = [
    {
        path: '',
        component: PublicComponent,
        canActivateChild: [ LogoutGuard ],
        children: [
            {
                path: '',
                component: GatewayComponent,
                loadChildren: () => import ('./gateway/gateway.module').then( m => m.GatewayModule)
            }
        ]
    }
];

export const PUBLIC_ROUTES = RouterModule.forChild( publicRoutes );
