import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BinnacleService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService ) { }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getList(  options: any = [] ): Observable<any> {

    return this.httpService.getList( `/binnacle`, options).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }




}
