import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpService } from '../platform/http.service';
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class BillingHouseService {
  authorization = '';
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private httpService: HttpService,
    public securityService: SecurityService,
    ) { 
    this.authorization = this.securityService.dataSession.authorization;

  }
// -----------------------------------------------------------------------------
//  Create immovable
// -----------------------------------------------------------------------------
  createOrder( data: any ): Observable<any> {

    return this.httpService.post( `/billing/house`,  data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getOrderList(  options: any = [] ): Observable<any> {

    return this.httpService.getList( `/billing/house`, options ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getOrderById( id: string): Observable<any> {

    return this.httpService.get( `/billing/house/${ id }` ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  Update immovable
// -----------------------------------------------------------------------------
  updateOrder( id: string, data: any ): Observable<any> {

    console.log(data);
    return this.httpService.patch( `/billing/house/${ id }`, data ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }

// -----------------------------------------------------------------------------
//  Delete user
// -----------------------------------------------------------------------------
  deleteOrder( id: string ): Observable<any> {

    return this.httpService.delete( `/billing/house/${ id }` ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
// -----------------------------------------------------------------------------
//  export Data
// -----------------------------------------------------------------------------
  exportData(): void{

    this.httpService.exportData(`/billing/house/export/bill`, this.authorization);

  }
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
sedByEmail( id: string, mail: string): Observable<any> {

  return this.httpService.get( `/billing/house/${ id }/pdf?mail=${ mail }` ).pipe( map( (response: any) => {

    return response;

  }, catchError( err => {

    return throwError(err);

  } )));

}
// -----------------------------------------------------------------------------
//  Get user list
// -----------------------------------------------------------------------------
  getDshboard(  year: number ): Observable<any> {

    return this.httpService.getList( `/billing/dashboard/sales/house/${ year }`, [] ).pipe( map( (response: any) => {

      return response;

    }, catchError( err => {

      return throwError(err);

    } )));

  }
}
