import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginGuard } from './guards/login.guard';
import { LogoutGuard } from './guards/logout.guard';
// import { DeactivateGuard } from './guards/deactivate.guard';

import { HttpService } from './platform/http.service';


import {
  LocalStorageService,
  SecurityService,
  AlertService,
  UsersService,
  NotificationService
//   ClientService,
//   PersonService,
//   PricesService,
} from './services.index';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    LoginGuard,
    LogoutGuard,
    // DeactivateGuard,

    HttpService,
    LocalStorageService,
    SecurityService,
    AlertService,
    NotificationService,

    UsersService
  ],
  declarations: []
})
export class ServicesModule { }
